import React, { FC, forwardRef, Ref, useContext } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { LinkTo } from '../linkTo/LinkTo';
import { ArrowRight } from '../icon';
import { useResponsiveType } from '../../hooks';
import { useDataLayerPush } from '../../hooks/useDataLayerPush';
import { getUrlParts } from '../../utils';
import { SectionContext } from '../../context/SectionContext';

export type LinkType = HTMLButtonElement | HTMLAnchorElement;
export type LinkTypes = React.ReactNode | React.PropsWithChildren<any> | string;

// Interface

export interface LinkProps
  extends Partial<Omit<HTMLButtonElement, 'children'>>,
    Partial<
      Omit<
        HTMLAnchorElement,
        'addEventListener' | 'removeEventListener' | 'children'
      >
    > {
  label?: string;
  url?: string;
  as?: string;
  onClick?: React.MouseEventHandler<LinkType>;
  target?: string;
  a11yTitle?: string;
  variant?: 'primary' | 'secondary';
  iconRight?: React.ReactNode;
  hideArrow?: boolean;
  children?: React.ReactNode;
  disableClickTracking?: boolean;
  customDataLayerEvent?: object;
  dataLayerEvent?: string;
  dataLayerClickContext?: string;
}

// Styles

// Fixes the React does not recognize the `XXX` prop on a DOM element.
const BLACKLISTED_PROPS = [
  'isLoading',
  'as',
  'hasLabel',
  'currentPage',
  'a11yTitle',
];

interface StyledLinkProps {
  variant?: 'primary' | 'secondary';
}

export const StyledLink = styled('a', {
  shouldForwardProp: (propName) =>
    // If not in black list then return true
    !BLACKLISTED_PROPS.includes(propName),
})<StyledLinkProps>`
  ${({ theme: { space, colors }, variant, hideArrow }) => css`
    display: flex;
    align-items: center;
    gap: ${space.xxxxxSmall};
    color: currentColor;
    flex-shrink: 0;
    text-decoration: none;
    ${variant === 'primary'
      ? useResponsiveType('bodyBold')
      : useResponsiveType('body')};

    &:hover {
      text-decoration: underline;
    }

    ${hideArrow &&
    css`
      text-decoration: underline;
    `};
  `};
`;

// JSX

export const Link: FC<LinkProps> = forwardRef(
  (
    {
      children,
      iconRight,
      label,
      url,
      as,
      target,
      a11yTitle,
      onClick,
      variant = 'primary',
      hideArrow,
      disableClickTracking,
      customDataLayerEvent,
      dataLayerEvent,
      dataLayerClickContext,
      ...rest
    }: LinkProps,
    ref: Ref<LinkType>
  ) => {
    let component: LinkTypes;
    const { sectionId } = useContext(SectionContext);
    const { dataLayerPush } = useDataLayerPush();

    if (as) {
      component = as;
    } else if (!url) {
      component = 'button';
    } else {
      component = LinkTo;
    }

    // Handle click
    const handleClick = (...props) => {
      if (!disableClickTracking) {
        dataLayerPush(
          customDataLayerEvent
            ? {
                ...customDataLayerEvent,
              }
            : {
                event: dataLayerEvent || 'link_click',
                clickText: label || children,
                clickContext: dataLayerClickContext || sectionId,
                ...getUrlParts(url as string),
              }
        );
      }
      if (onClick) {
        onClick(...props);
      }
    };

    let icon = null;
    if (iconRight) {
      icon = iconRight;
    } else if (hideArrow) {
      icon = null;
    } else {
      icon = <ArrowRight size="small" />;
    }

    return (
      <StyledLink
        ref={ref}
        as={component}
        onClick={handleClick}
        target={target && '_blank'}
        aria-label={a11yTitle}
        variant={variant}
        hideArrow={hideArrow}
        {...(url && { to: url })}
        {...rest}
      >
        {children || label}
        {icon}
      </StyledLink>
    );
  }
);

export default Link;
