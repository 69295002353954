import React, { FC } from "react";
import { Icon, IconProps } from "../Icon";

export const CustomBullet: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M22.268 12.268v-2.145c-4.343-.563-6.89.054-9.008.483V2h-2.493c-.188 5.416-4.504 9.303-9.035 9.732v2.145c4.343.563 6.89-.054 9.008-.483V22h2.493c.188-5.416 4.504-9.303 9.035-9.732Z"
    />
  </Icon>
);
